@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400;1,500;1,600&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: 'Montserrat', sans-serif;
}

.mobile-menu {
    height: calc(100vh - 65px);
    position: absolute;
    top: 63px;
}


.carousel .carousel-status {
    display: none;
 }

.dark {
    color: #021224;
}


/* logo slider  */
.slide {
    perspective: 50px;
  }
  
.slide-track {
  animation: scroll 18s linear infinite;
}


@keyframes scroll {
  0% {
    transform: translateX(0)
  }
  100%{
    transform: translateX(calc(-250px * 4));

  }
}
/* logo slider  */


/* swiper  */
.swiper-pagination-bullet-active {
  background-color: red !important;
}


.swiper-button-next,
.swiper-button-prev {

    right:10px;
    color: red !important;
    fill: black !important;
    stroke: black !important;
}
/* swiper  */

/* ceo  */


.info-ceo {
  direction: rtl;
}

.info-ceo > * {
  direction: ltr;
}


/* ceo  */


@media only screen and (max-width: 991px){
  .swiper-slide {
      background-size: contain !important;
      background-repeat: no-repeat;
      background-position: 0 50px !important;
  }
}